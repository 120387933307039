Dialog {
    padding: 1px;
    border: 2px solid gray;
}

table {
    padding-bottom: 1.5rem;
    border-spacing: 3px 0;

}

.promoName {
    font-weight: bold;
    text-align: center;
}

.itemItem {
    text-align: right;
    padding-right: 0.5rem;
}

.itemValue {
    white-space: pre-wrap;
}

tr:nth-child(odd) {
    background: #eeffff;
}

tr:nth-child(even) {
    background: #ddffff;
}

td{
    padding:0.6rem 0;
}