.tooltip {
  font-size: 0.9rem;
  line-height: 0.9rem;
  background-color: azure;
  padding: 0.3rem;
  border: 1px solid gray;
  border-radius: 1rem;
}

@media (max-width:600px) {
  .tooltip {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
}

.sum {
  font-weight: bold;
}

.tooltip p {
  margin: 0;
  padding: 0;
}

.tooltipHr {
  border-top: 1px dashed gray;
  margin: 0;
  padding: 0;
}

.graphName {
  font-size: 1.1rem;
  text-align: center;
  margin-top: 1rem;
}