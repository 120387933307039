.tooltip {
  font-size: 0.9rem;
  line-height: 0.9rem;
  background-color: azure;
  padding: 0.3rem;
  border: 1px solid gray;
  border-radius: 1rem;
}

@media (max-width:600px) {
  .tooltip {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
}

.tooltip>* {
  margin: 0;
  padding: 0;
}

.tooltipHr {
  border-top: 1px dashed gray;
  margin: 0;
  padding: 0;
}

.totalPointPer {
  margin-left: 0.5rem;
}

.tooltipUl {
  margin: 0;
  padding-inline-start: 0;
  list-style: none;
}