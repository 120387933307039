.selectable {
  cursor: pointer;
}

.details {
  border: 1px solid gray;
}

.summary {
  cursor: pointer;
  text-align: center;
  font-size: large;
  background-color: #eeeeee;
}

.summary:hover {
  font-weight: bold;
  background-color: azure;
}

.inputForm>* {
  padding: 0.8rem 0.1rem;
}

.inputForm>*:nth-child(odd) {
  background-color: #F2D6D6;
}

.inputForm>*:nth-child(even) {
  background-color: #FDEFC5;
}

.promoItem {
  /* 「float:right」な子要素の高さを保持するため */
  overflow: auto;
}

.promoItem>* {
  display: inline;
}

.promoItem>*:last-of-type {
  float: right;
  margin-right: 0.5rem;
}

.promoItem select {
  margin-left: 1rem;
}

.rank {
  line-height: 2rem;
  margin-left: 0.5rem;
}

.rank input {
  cursor: pointer;
}

.rank label {
  margin-right: 1rem;
  cursor: pointer;
}